.footerLogoText {
    justify-content: flex-end;
    font-size: 35px;
    font-weight: 300;
}

@media screen and (max-width: 641px) {
    .footerLogoText {
        font-size: 25px;
    }
}