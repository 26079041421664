.donation-error-page {
    text-align: center;
    padding: 20px;
    color: grey;
}

.donation-error-page .confirm-icon {
    font-size: 40px;
    color: red;
}

.donation-error-page h1 {
    margin-bottom: 30px;
    font-family: 'Rokkitt', serif;
}


.kindness-ref {
    font-size: 12px;
}