.spiritual-gifts-assessment-form-page {
    padding: 2rem;
    text-align: justify;
}

.spiritual-gifts-assessment-form-content {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.answer-list {
    margin-top: 0.5rem;
    margin-bottom: 2rem;
}


.spiritual-gifts-assessment-form-page h1 {
    text-align: center;
}

.spiritual-gifts-assessment-form-content h1 {
    text-align: center;
}

.gt-question {
    font-weight: bold;
}

.spiritual-gifts-assessment-form-page h2 {
    text-align: center;
    margin-bottom: 1rem;
}

.spiritual-gifts-assessment-form-page h3 {
    text-align: center;
    margin-bottom: 1rem;
    font-size: 1.2rem;
    color: rgb(200, 200, 200);
    font-weight: bold;
}

.gt-error {
    font-size: 0.8rem;
    font-style: italic;
    color: red;
}

.spiritual-gifts-assessment-description {
    padding-bottom: 4rem;
}

.question-list, .description-list {
    gap: 1rem;
    display: flex;
    flex-direction: column;
}

.answer-item {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.spiritual-gifts-assessment-form-page .required {
    color: red;
}

.spiritual-gifts-assessment-form-buttons-div {
    display: flex;
    gap: 1rem;

}

input[type="radio"] {
    display: inline-block;
    vertical-align: middle;
    background-origin: content-box;
    border: 1px solid #adb5bd;
    border-radius: 50%;
    width: 0.8em;
    height: 0.8em;
    transition: background-color 0.15s ease-in-out;
    margin: 0;
    cursor: pointer;
}


.spiritual-gifts-assessment-form-buttons {
    width: fit-content;
    min-width: 80px;
    background-color: blue;
    border-radius: 5px;
    color: white;
    padding: 5px 10px;
    cursor: pointer;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s;
}

.gt-input {
    cursor: pointer;
}

.spiritual-gifts-assessment-form-buttons:hover {
    background-color: rgb(0, 145, 255);
}

.saved-text:hover {
    color: unset;
}

.instruction-content {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.gt-go-to-bottom {
    position: sticky;
    top: 68vh;
    width: fit-content;
    align-self: flex-end;
    display: flex;
    gap: 1rem;
}

.gt-go-to-bottom div {
    width: fit-content;
    background-color: white;
    box-shadow: 0 0 5px grey;
    color: grey;
    cursor: pointer;
}

.gt-go-to-bottom div:hover {
    color: black;
}

a:hover {
    text-decoration: none;
}

.spiritual-gifts-assessment-client-info {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1.5rem;
    padding: 2rem 0 3rem 0;
    border-top: solid rgb(209, 209, 209) 1px;
}

.spiritual-gifts-assessment-client-info label {
    margin-bottom: 5px;
}

.gt-info-input {
    border-radius: 5px;
    border: 1px solid rgb(209, 209, 209);
    height: 2.5rem;
    width: 50%;
    padding: 5px;
}

.spiritual-gifts-assessment-form {
    padding-top: 2rem;
    display: flex;
    flex-direction: column;
    border-top: 1px solid rgb(209, 209, 209);
}

.gt-undone-div {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    margin: 1rem;
    padding-bottom: 2rem;
}

.gt-undone-item div {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: grey;
    border-radius: 50% 50%;
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    color: white;
}

.gt-undone-item div:hover {
    background-color: rgb(194, 194, 194);
}

.gt-undone-item {
    text-decoration: none;
}

.gt-undone-item:hover {
    text-decoration: none;
}

.gt-notification {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 60vh;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    color: grey;
}

.gt-event-notification {
    display: block;
    height: 4rem;
    font-size: 0.8rem;
    font-style: italic;
    margin: 5px;
}

.gt-event-notification-action {
    text-decoration: unset;
    display: inline-block;
}

.gt-event-notification-action:hover {
    text-decoration: unset;
    color: red;
    cursor: pointer;
}

.gt-save-noti-unsuccess {
    color: red;
}

#gt-copy-tooltip {
    opacity: 0;
    transition: all 0.5s;
    color: rgb(255, 0, 0);
}

.result-image {
    width: 80%;
    margin: auto;
}

.submit-loading-icon {
    animation: spin 2s infinite linear;
    width: fit-content;
    height: fit-content;
}

.spiritual-gifts-assessment-language {
    border-radius: 5px;
    border: 1px solid rgb(209, 209, 209);
    height: fit-content;
}

.spiritual-gifts-assessment-form-result-to-print {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.spiritual-gifts-assessment-result-sub-div {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.gt-print-result {
    cursor: pointer;
    font-size: 0.8rem;
    font-style: italic;
    color: grey;
}

.gt-print-result:hover {
    color: blue;
}

.gt-save-copy-link {
    display: flex;
    gap: 0.5rem;
}

.gt-save-copy-link-url {
    padding: 2px 5px;
    border: solid rgb(184, 184, 184) 1px;
    background-color: #ddeeff;
    word-wrap: unset;
    color: grey;
    font-size: 0.8rem;
}

.gt-save-copy-link-button {
    height: fit-content;
}

.gt-save-notification-modal {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0 1rem;
}

.gt-checkout-language {
    display: flex;
    top: 4rem;
    position: fixed;
    right: 2rem;
    bottom: auto;
    font-size: 1rem;
    gap: 1rem;

}

.function-buttons {
    display: block;
    z-index: 10;
    bottom :20%;
    position: fixed;
    right: 3rem;
}

.function-buttons .button {
    display: inline-block;
    width: fit-content;
    border: #b9c8d7 2px solid;
    margin-left: 0.5rem;
}


@media print {
    @page {
        margin: 5rem;
    }
}

@media screen and (max-width: 800px) {
    .spiritual-gifts-assessment-form-page {
        margin-top: 2rem;
        padding: 3rem 1rem;
    }

    .result-image {
        width: 100%;
    }

    .spiritual-gifts-assessment-form-page h1 {
        font-size: 30px;
    }

    .spiritual-gifts-assessment-form-content {
        width: 100%;
        padding: 1rem;
        gap: 1rem;
    }

    .spiritual-gifts-assessment-form-buttons {
        justify-content: center;
        font-size: 0.7rem;
        padding: 5px;
        min-width: 50px;
    }

    .spiritual-gifts-assessment-form-buttons-div {
        justify-content: center;
    }

    .gt-undone-item div {
        width: 1.5rem;
        height: 1.5rem;
        font-size: 0.7rem;
    }

    .gt-event-notification {
        font-size: 0.7rem;
        text-align: center;
    }

    .gt-undone-div {
        gap: 0.5rem;
        margin: 5px 0;
    }

    .gt-checkout-language {
        top: 3.5rem;
        right: 0.5rem;
    }

    .spiritual-gifts-assessment-checkout-page-payment {
        width: 100%;
    }

    .gt-info-input {
        width: 100%;
    }

    .function-buttons {
        right: 0.5rem;
    }

}

@media screen and (max-width: 1200px) {
    .spiritual-gifts-assessment-form-page {
        padding: 0;
    }

    .spiritual-gifts-assessment-form-content {
        width: 100%;
    }
}