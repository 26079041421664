
.privacy-policy-content {
    max-width: 1000px;
    margin: auto;
}

.privacy-policy-page h1 {
    /* font-family: 'Quicksand', sans-serif; */
    text-align: center;
    font-weight: 300;
    padding: 10px;
}

.privacy-policy-page h2 {
    /* font-family: 'Quicksand', sans-serif;  */
    font-weight: bold;
    font-size: 16px;
}

.privacy-policy-page h3 {
    /* font-family: 'Quicksand', sans-serif;  */
    font-size: 16px;
    font-weight: 500;
}

.privacy-policy-text {
    background-color: var(--background-color);
}

.privacy-policy-text a {
    text-decoration: none;
    color: var(--hover-color2);
}

.policy-grid {
    display: grid;
    width: 700px;
    grid-template-columns: 25% 55% auto;
}

.policy-grid-item {
    padding: 5px;
    margin: 0;
    border: 1px solid white;
}

.policy-grid-title {
    font-weight: bold;
}

.agreement {
    text-align: center;
    margin-bottom: 20px;
}

@media (max-width: 500px) {
    .privacy-policy-page {
        width: 100%;
    }
}
