.spiritual-gifts-assessment-checkout-page {
    max-width: 1200px;
    display: flex;
    padding: 1rem;
    gap: 1rem;
    background-color: var(--background-color);
    flex-wrap: wrap;
    overflow: auto;
}

.spiritual-gifts-assessment-checkout-page-description {
    text-align: justify;
    flex: 1;
    min-width: 300px;
    flex-shrink: 0;
}

.spiritual-gifts-assessment-checkout-page-payment {
    flex: 1;
    min-width: 300px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.spiritual-gifts-assessment-checkout-page h1 {
    /* font-family: 'Rokkitt', serif; */
    text-align: center;
    font-size: 30px;
}

.spiritual-gifts-assessment-payment-grid {
    display: grid;
    width: 100%;
    grid-template-columns: auto 20% 20%;
    grid-template-areas: 
        "a a a"
        "b c d"
        "g e f";
}

.spiritual-gifts-assessment-payment-grid div {
    padding: 5px;

}

.spiritual-gifts-assessment-grid-item7 {
    grid-area: g;
}

.spiritual-gifts-assessment-grid-item1 {
    grid-area: a;
    border-bottom: 2px solid rgb(201, 201, 201);
    font-size: 1.2rem;
    font-weight: bold;
}

.spiritual-gifts-assessment-grid-item2 {
    grid-area: b;
}

.spiritual-gifts-assessment-grid-item3 {
    grid-area: c;
    text-align: right;
}

.spiritual-gifts-assessment-grid-item4 {
    grid-area: d;
    text-align: right;
}

.spiritual-gifts-assessment-grid-item5 {
    grid-area: e;
    border-top: 2px solid rgb(201, 201, 201);
    text-align: right;
    font-weight: bold;
}

.spiritual-gifts-assessment-grid-item6 {
    grid-area: f;
    border-top: 2px solid rgb(201, 201, 201);
    text-align: right;
    font-weight: bold;
}

.spiritual-gifts-assessment-order-button {
    display: flex;
    align-items: flex-end;
    width: 100%;
    flex-direction: column;
}

.spiritual-gifts-assessment-order-button p {
    cursor: pointer;
    background-color: #0000FF;
    color: white;
    width: fit-content;
    padding: 5px 10px;
    border-radius: 5px;
}

.spiritual-gifts-assessment-checkout-client-info {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 0.5rem;
}

.spiritual-gifts-assessment-checkout-client-info label {
    margin-bottom: 5px;
    font-size: 0.8rem;
}

.gt-checkout-info-input {
    border-radius: 5px;
    border: 1px solid rgb(209, 209, 209);
    height: 2rem;
    width: 100%;
    max-width: 300px;
    padding: 5px;
}

.gt-error {
    font-size: 0.8rem;
    font-style: italic;
    color: red;
}

.gt-checkout-language {
    display: flex;
    position: fixed;
    flex-direction: column;
    top: 0;
    left: auto;
    right: 30px;
    bottom: auto;
    font-size: 1rem;
    gap: 1rem;

}

.home-language {
    display: flex;
    gap: 1rem;
    font-size: 1rem;

}

@media screen and (max-width: 1200px) {
    .spiritual-gifts-assessment-checkout-page {
        padding-top: 3rem;
    }

    .gt-checkout-language {
        flex-direction: row;

    }
}

@media screen and (max-width: 800px) {

    .spiritual-gifts-assessment-checkout-page {
        flex-direction: column;
        padding: 2.5rem 2rem;
    }

    .spiritual-gifts-assessment-checkout-page-description {
        width: 100%;
    }

    .gt-checkout-language {
        font-size: 0.6rem;
    }

    .spiritual-gifts-assessment-checkout-page-payment {
        width: 100%;
    }


}


@media screen and (max-width: 400px) {
    .spiritual-gifts-assessment-checkout-page {
        flex-direction: column;
        padding: 2.5rem 0;
    }

    .gt-checkout-info-input {
        width: 250px;
        max-width: unset;
    }

}