.reset-password-page {
    margin: auto;
    margin-top: 2rem;
}

.login-container3 {
    width: 40rem;
    margin: 20px auto;
    background-color: grey;
    border-radius: 15px;
    border: #dce0e4 1.5px solid;
    height: auto;
    box-shadow: 0 8px 22px 0 rgb(37 44 97 / 15%), 0 4px 6px 0 rgb(93 100 148 / 20%);
}

.forgot-password {
    justify-content: center;
}

.show-password-icon3 {
    position: absolute;
    right: 1rem;
    top: 3.5rem;
    transform: translateY(-50%);
}

@media screen and (max-width: 641px) {
    .login-container3 {
        width: 90%;
    }
}