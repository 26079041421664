.term-of-use-page {
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 2rem;

}

.term-of-use-page h2 {
    font-weight: bold;
    font-size: 16px;
}

.term-text {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: var(--background-color);
    margin: auto;
}
