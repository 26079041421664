.help-menu {
    font-size: 16px;
    font-weight: 600;
}

.dropdown-toggle {
    color: white;
}

.about-us-icon {
    height: 22px;
}

.text-help {
    color: black;
}

.slogan-help {
    text-align: center;
}


.icon-help {
    color: white;
    font-size: 24px;
    margin-right: 10px;
    background-color: #9aa6b2;;
}

#set {
    margin: 0;
    padding: 0;
}

#dropdown {
    background-color: white;
    border: none;
    width: 90%;
    padding: 5px 0;
    border-radius: 0;
    text-align: left;
    margin-left: 24px;
}

#drop-item:focus {
    background-color: #e9ecef;
}

#drop-item:hover .text-help {
    color: black;
}

#dropdown:hover {
    background-color: #e9ecef;
}

#dropdown:hover .text-help {
    color: black;
}

#dropdown:focus {
    background-color: #e9ecef;
}

