
#history-modal {
    max-width: 900px; /* set a maximum width */
    width: auto;
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
}


#del-tooltip {
    width: auto;
    font-size: 0.8rem;
    font-style: italic;
}

#del-tooltip p {
    margin: 0;
}

#history-modal .modal-content {
    max-height: 80vh; /* Set the height */
    height: auto;
    overflow-y: scroll;
    max-width: 800px;
    min-width: 320px;
    width: auto;
}

.history-select-checkbox {
    font-size: 0.8rem;
    height: 16px;
    width: 16px;
    cursor: pointer;
    width: auto;
    margin-top: 2px;
}

.history-select-checkbox-div {
    display: flex;
    width: fit-content;
    padding-top: 5px;
    gap: 12px;
}

.history-select-checkbox-div p {
    margin: 0;
    font-size: 0.8rem;
}

.history-body-header label span {
    margin-top: -5px;
}


.dropdown-divider {
    margin: 0.1rem 0;
}

#history-item {
    display: block;
    height: 40px;
    width: 260px;

}

.history-button {
    font-size: 0.8rem;
    width: 80px;
    padding: 5px;
}

.history-footer {
    position: sticky;
    bottom: 0;
    z-index: 1;
    background-color: white;
}

.history-div .line {
    height: 3px;
    width: 96%;
    margin: auto;
    border-bottom: solid #dedede 1px;
    margin-bottom: 2px;
}



.history-list-item {
    font-size: 1rem;
    margin-bottom: 0;
    padding: 3px 10px;
    margin-left: 30px;
    min-height: 56px;
    cursor: pointer;
}

.history-list-item p {
    margin-bottom: 0;
}

#his-link {
    text-decoration: none;
    color: grey;
}

.history-list-item:hover {
    text-decoration: none;
    background-color: #dedede;
}

.history-list-item a {
    text-decoration: none;
    color: rgb(95, 95, 95);
}

#history-item:focus {
    background-color: #e9ecef;
}

#history-text {
    margin-top: 8px;
    margin-bottom: 0;
}

.star {
    color: white;
}

#history-item .clock {
    color: white;
    padding: 5px 3px 3px 3px;
    height: 38px;
    width: 30px;
    margin-right: 12px;
    margin-top: -8px;
    background-color: #9aa6b2;
}

.history-main-header {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 2;
}

.history-body-header {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    background-color: white;
    padding: 10px 30px;
    gap: 1rem;

}

.his-item-div {
    position: relative;
}

.history-body-header .handle-list-div {
    display: flex;
    width: fit-content;
    gap: 10px;
    flex-wrap: wrap;
}

.history-checkbox {
    display: block;
    position: absolute;
    top: 8px;
    left: 0;
    height: 16px;
    width: 16px;
    cursor: pointer;

}

.footer-flex {
    display: flex;
    height: 40px;
    width: 100%;
    justify-content: space-between;
    padding: 0 20px;
}

.pagination {
    cursor: pointer;
}


@media screen and (max-width: 800px) {
    #history-modal .modal-content {
        margin: auto;
        width: 80%;
        height: auto;
    }

    .history-list-item {
        font-size: 0.8rem;
    }

    #history-modal .modal-body {
        padding-top: 5px;
    }

    .history-button {
        font-size: 0.8rem;
        padding: 5px;
    }

}

@media screen and (max-width: 400px) {
    #history-modal .modal-content {
        margin: auto;
        width: 90%;
    }

    .history-body-header {
        padding: 1rem;
    }

    .history-body-header label {
        font-size: 0.7rem;
    }

    .history-button {
        font-size: 0.7rem;
        width: 75px;
        padding: 2px;
    }

}


