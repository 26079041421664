.app {
  min-height: 100vh;
  background-image: url('./res/background-1600.png');
  background-size: contain;
  background-position: bottom right;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-color: var(--app-background);
}

.app-content {
  display: block;
  width: 100%;
  overflow: auto;
  position: relative;
  color: white;
  padding: 4rem 1rem;
  max-height: 80vh;
}

.app-content > div {
  max-width: 1200px;
  margin: auto;
}

.nav-buttons {
  position: fixed;
  right: 0;
  top: 0;
  height: 4rem;
  padding: auto 2rem;
  z-index: 11;
  background-color: var(--app-background);
  width: 100%;
}
.nav-buttons .dropdown-menu {
  max-height: 80vh !important;
  overflow-y: scroll !important;
}
.nav-buttons > div {
  display: flex;
  gap: 1rem;
  float: right;
  align-items: center;
  height: 100%;
}

.app-flex {
  display: flex;
}

.width-full {
  width: 100%;
}

/* flex */
.flex {
  display: flex;
}

.gap-1 {
  gap : 0.25rem;
}

.gap-2 {
  gap : 0.5rem;
}
.gap-3 {
  gap : 0.75rem;
}

.gap-4 {
  gap : 1rem;
}

.self-center {
  align-self: center;
}

.self-end {
  align-self: flex-end;
}

.justify-center {
  justify-content: center;
}

.align-center {
  align-items: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-end {
  justify-content: flex-end;
}

.fit-content {
  width: fit-content;
}

.error-yellow {
  color: yellow;
  font-style: italic;
  font-size: 0.8rem;
}

/* button */
.button {
  cursor: pointer;
  padding: 5px 15px;
  border-radius: 0.5rem;
  transition: all 0.2s;
}

.button-style1 {
  background-color: black;
  color: white;
}

.button-style2 {
  background-color: #b9c8d7;
  color: black;
}

.button-style1:hover {
  background-color: rgb(37, 37, 37);
}

.button-style2:hover {
  background-color: rgb(223, 223, 223);
}

/* text */
.font-size-small {
  font-size: 0.8rem;
}

.font-size-medium {
  font-size: 1rem;
}

.font-size-large {
  font-size: 1.5rem;
}

.font-size-xlarge {
  font-size: 2rem;
}

.font-size-small-title {
  font-size: 3rem;
}

.font-size-title {
  font-size: 4rem;
}

.text-right {
  text-align: right;
}

.font-italic {
  font-style: italic;
}

.font-bold {
  font-weight: bold;
}

.text-white {
  color: white;
}

.text-black {
  color: black;
}

/* container */
.box-style1 {
  background-color: grey;
  color: black;
  padding: 1rem 2rem;
  border-radius: 20px;
  width: fit-content;
}

/* link */

/* 
.link-style1 {
    text-decoration: none;
    color: white;
    cursor: pointer;
    transition: all 0.3s ;
    border-bottom: transparent solid 2px;
}

.link-style1:hover {
    color: #3498db;
    text-decoration: underline;
    transition: all 0.3s ;
} */

a,
a:hover,
a:focus {
  text-decoration: unset;
}

.link-style1 {
  position: relative;
  text-decoration: none;
  color: white;
  width: fit-content;
  cursor: pointer;
}

.link-style1:hover {
  color: white;
}

.link-style1:before,
.link-style1:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 2px;
  background-color: #3498db; /* Change the color here */
  transition: width 0.3s ease-in-out, left 0.3s ease-in-out;
}

.link-style1:before {
  transform: translateX(-50%);
}

.link-style1:after {
  transform: translateX(-50%);
}

.link-style1:hover:before,
.link-style1:hover:after {
  width: calc(100% - 5%); /* Adjust the length of the line */
}

.link-style1:focus:before,
.link-style1:focus:after {
  width: calc(100% - 5%); /* Adjust the length of the line */
}

/* list */

.normal-list > div,
.bullet-list > div {
  position: relative;
  padding: 0.3rem;
  padding-left: 2rem; /* Adjust as needed */
}

.bullet-list > div::before {
  content: '\2022'; /* Unicode character for a bullet (•) */
  color: white; /* Adjust color as needed */
  font-size: 2rem; /* Adjust size as needed */
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  margin-right: 10px; /* Adjust space between bullet and text as needed */
}

/* scroll bar */
::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: #cb6966;
}

::-webkit-scrollbar-track {
  background-color: #444444;
}

.basic-nav-dropdown {
    width: fit-content;
    word-wrap: break-word;
    background-color: var(--background-color);
    border-bottom: solid transparent 2px;
    transition: 0.3s all;
}

.basic-nav-dropdown:hover {
    border-bottom: solid #3498db 2px;
    color: #0056b3;
}

@media screen and (min-width: 1600px) {
  .app {
    background-image: url('./res/background-3000.jpg');
    background-size: cover;
  }
}

@media screen and (max-width: 1600px) {
  .nav-buttons > div {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
  }
}

/* Mobile and Tablet Devices*/
@media screen and (max-width: 800px) {
  .app {
    background-image: url('./res/background-800.png');
    background-size: cover;
  }

  .app-flex {
    display: block;
    position: relative;
  }

  .nav-buttons > div {
    gap: 0;
  }

  .basic-nav-dropdown {
      font-size: 0.8rem;
  }
}

@media screen and (max-width: 400px) {
  .app {
    background-image: url('./res/background-800.png');
    background-size: contain;
  }

  .app-content {
    padding: 3rem 1rem;
  }

  .button {
    padding: 5px;
    font-size: 0.8rem;
  }

  .font-size-small {
    font-size: 0.7rem;
  }

  .font-size-medium {
    font-size: 0.9rem;
  }

  .font-size-large {
    font-size: 1.2rem;
  }

  .font-size-xlarge {
    font-size: 1.5rem;
  }

  .font-size-small-title {
    font-size: 2rem;
  }

  .font-size-title {
    font-size: 3rem;
  }

  .box-style1 {
    padding: 0.5rem 1rem;
    border-radius: 10px;
  }

  .nav-buttons {
    height: 3rem;
  }
}

input[type='checkbox'] {
  cursor: pointer;
}
