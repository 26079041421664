.login-container {
    width: 60%;
    margin: 20px auto;
    background-color: white;
    border-radius: 15px;
    border: #dce0e4 1.5px solid;
    height: auto;
    box-shadow: 0 8px 22px 0 rgb(37 44 97 / 15%), 0 4px 6px 0 rgb(93 100 148 / 20%);
}

.forgot-password {
    justify-content: center;
}

@media screen and (max-width: 641px) {
    .login-container {
        width: 80%;
    }
}