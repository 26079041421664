.spiritual-gifts-assessment-error-page {
    text-align: center;
    padding: 20px;
    color: grey;
    height: 60vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.spiritual-gifts-assessment-error-page .confirm-icon {
    font-size: 40px;
    color: red;
}

.spiritual-gifts-assessment-error-page h1 {
    margin-bottom: 30px;
    /* font-family: 'Rokkitt', serif; */
}

