/* pagination */
.pagination-container {
    display: flex;
    gap: 0.5rem;
    align-items: center;
}

.pagination-button {
    cursor: pointer;
}

.pagination-button:hover {
    color: var(--hover-color2);
}

.pagination-button.current {
    border: white 1px solid;
    padding: 0 0.2rem;
}