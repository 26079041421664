.howtouse-page-content {
    position: relative;
}
.app-content {
    padding-bottom: 0;
}
/* 
.howtouse-page .title {
    font-size: 48px;
}

.howtouse-page-body > div {
    display: flex;
    gap: 1rem;
    align-items: center;
}


.howtouse-iframe {
    height: 640px;
    width: 90%;
}


@media screen and (max-width: 800px) {
    .howtouse-iframe {
        height: 480px;
        width: 90%;
    }
} */

.howtouse-iframe {
    width: 100%;
    height: 60vh;
    position: relative
    /* z-index: 1000;
    margin:auto;
    position: absolute;
    top: 0;
    left: 0; */
}


.howtouse-page-content .bottom {
    position: absolute;
    bottom: 0.5rem;
    color: grey;
    width: 100%;

}


.how-to-use-basic-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 4rem;
    font-weight: bold;
}


.how-to-use-basic-content > div {
    display: flex;
    gap: 1rem;
    align-items: flex-start;
}


.popup-overlay {
    background: var(--background-color);
}


