.change-password-content.box-style1 {
    margin: auto;
    width: 100%;
    max-width: 600px;
}

.forgot-password {
    justify-content: center;
}

.form-group {
    position: relative;
}

.show-password-icon4 {
    position: absolute;
    right: 2rem;
    top: 3.5rem;
    transform: translateY(-50%);
}

@media screen and (max-width: 641px) {
    .login-container {
        width: 80%;
    }
}


.btn-close {
    box-sizing: content-box;
    width: 1em;
    height: 1em;
    padding: 0.25em;
    color: #000;
    border: 0;
    border-radius: 0.375rem;
    opacity: .5;
}

.btn-close:hover {
    color: #000;
    text-decoration: none;
    opacity: .75;
}

.btn-close:focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
    opacity: 1;
}