.language-option {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 5px 10px;
    border: #b9c8d7 2px solid;
    background-color: black;
    color:white;
}


.language-option.focus {
     background-color: #b9c8d7;
    color: black;
}

.language-option.focus:hover {
    background-color: #b9c8d7;
}

.language-option:hover {
    background-color: #2f2f2f;
}

.flagicon {
    height: 1.2rem;
    width: 1.2rem;
    margin: 0;
}

@media screen and (max-width: 800px) {
    .language-option {
        gap: 0.2rem;
        margin-top: 0.2rem;
    }

    .flagicon {
        height: 0.8rem;
        width: 0.8rem;
    }

}