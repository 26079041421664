.thank-you-page {
    text-align: center;
    padding: 20px;
    color: grey;
}

.thank-you-box {
    width: 60%;
    margin: auto;
}

.thank-you-page .confirm-icon {
    font-size: 40px;
    color: green;
}

.thank-you-page h1 {
    margin-bottom: 30px;
    font-family: 'Rokkitt', serif;
}

.thank-you-page h2 {
    margin-bottom: 10px;
    font-size: 22px;
    text-align: left;
}

.kindness-quote {
    font-style: italic;
    text-align: right;
    margin-bottom: 30px;
}

.thank-you-page .link {
    text-align: right;
}

.small {
    font-size: 14px;
}

.kindness-ref {
    font-size: 12px;
}

@media (max-width: 700px) {
    .thank-you-box {
        width: 90%;
        margin: auto;

    }

    .thank-you-page h1 {
        font-size: 28px;
    }

    .thank-you-page h2 {
        margin-bottom: 30px;
        font-size: 16px;
        text-align: justify;
    }

}