.image-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--background-color);
    display: flex;
    justify-content: center;
    align-items: center;
}

.image-modal img {
    max-width: 90%;
    max-height: 90%;
    object-fit: contain;
    width: 90vw;
}

.main-image-modal {
    width: 100%;
    height: 100%;
}
