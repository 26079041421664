
.donation-page {
    background-color: var(--background-color);
    height: 80vh;
    overflow: auto;
}

button {
    all: unset;
    text-align: center;
}

.donation-grid {
    display: grid;
    grid-template-columns: 50% auto;
    margin: auto;
    width: 80%;
    grid-column-gap: 5vw;
}

.donation-grid .right-section {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.donation-restriction {
    grid-area: 3/2/span 1/span 2;
    font-style: italic;
    color: grey;
    width: auto;
}

img {
    width: 300px;
    margin: auto;
}

.rate-group {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(3, 1fr);
}

.rate {
    width: 100%;
    padding: 10px 0 10px 0;
    position: relative;
    /* font-family: 'Nunito', sans-serif; */

}

.otherrate {
    position: relative;
    grid-area: 2/2/span 1/span 2;
}

#amount-input {
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    text-align: center;
    height: 100%;
    background-color: lightgrey;
    border: rgb(185, 184, 184) solid 1px;
}

#amount-input:focus {
    background-color: white;
}

#amount-input:active {
    background-color: white;
}

.numrate {
    border: solid rgb(176, 176, 176) 1px;
}

/* .numrate:hover {
    color: white;
    animation: changecolor 0.3s ease-in forwards;
} */

.numrate:focus {
    color: white;
    animation: changecolor 0.3s ease-in forwards;
}


.donate-button {
    background-color: blue;
    color: white;
    width: 100%;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
    padding-top: 10px;
    padding-bottom: 10px;
}

.donate-button:hover {
    background-color: green;
}

.donate-button p {
    text-align: center;
    margin-bottom: 2px;
    padding: 0;
    font-size: 20px;
}

.c-select {
    width: 70px;
    padding: 5px;
    border-radius: 5px;
    background-color: rgb(227, 242, 255);
}

.donor-info {
    width: 280px;
    border: rgb(185, 184, 184) solid 2px;
    padding: 1vh;
    margin-bottom: 5px;
}


.donate-now {
    font-size: 30px;
    margin: 0;
    /* font-family: 'Rokkitt', serif; */
}

.frequency-select .f-radio {
    margin-right: 30px;
    margin-left: 5px;
    /* font-family: 'ABeeZee', sans-serif; */
}


.donation-description {
    /* font-size: 12px;
    margin-top: 2vh; */
    text-align: justify;
}

.input-field {
    padding: 5px;
    width: 400px;
    border: lightgrey 1px solid;
    border-radius: 5px;
}

.error {
    color: red;
    font-size: 12px;
}


.donation-page label {
    margin-top: 5px;
}

.donation-title {
    text-align: left;
}

#info-input {
    margin-bottom: 30px;
}

.info-label {
    font-weight: 700;
    color: grey;
}

.donation-quote {
    font-style: italic;
}

.min-donate {
    color: red;
    padding: 0;
    margin: 0;
}

@media (max-width: 1200px) {
    .donation-grid {
        width: 100%;
        padding: 1rem;
    }
}

@media (max-width: 800px) {
    .donation-grid {
        display: grid;
        grid-template-columns: auto;
        margin: 0px;
    }
}

@media (max-width: 400px) {
    .donation-grid {
        padding: 0;
    }

    .donation-grid-item {
        padding: 0;
    }

    .rate-group {
        margin: auto;
        display: grid;
        grid-gap: 5px;
    }

    .form {
        width: 100%;
    }

    .donate-button {
        width: 100%;
    }

    #submit-button:disabled {
        background-color: grey;
    }

    .item1 {
        grid-area: 1/1/span 1/span 1;
        text-align: center;
    }

    .input-field {
        width: 100%;
    }
}

@keyframes changecolor {
    to {
        background-color: rgb(93, 93, 255);
    }
}

@keyframes changecolorDonate {
    to {
        background-color: red;
    }
}