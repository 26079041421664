.app-search.box-style1 {
    margin: 0 0.5rem;
}

.search-box {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    justify-content: center;

}

.search-box input {
    width: 350px;
    border-radius: 5px;
}

.search-box .search-input>div {
    position: relative;
    width: fit-content;
}

.search-box .search-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    padding: auto;
    cursor: pointer;
}

.search-box-content {
    display: flex;
    flex-wrap: wrap;
}

.search-box .search-button:hover {
    color: var(--hover-color);
}

@media screen and (max-width: 800px) {
    .search-box input {
        width: 280px;
    }
    .app-search.box-style1 {
        margin: 0.8rem 0.8rem;
        padding: 0.5rem;
    }
}

@media screen and (max-width: 400px) {
    .search-box input {
        width: 250px;
    }
    .app-search.box-style1 {
        margin: 0.8rem 0.8rem;
        padding: 0.5rem;
    }
}