.icon-audio {
  color: white;
  background: #004d76;
}

.readmore-box .button {
  color: white;
  background: #004d76;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.readmore-box .button:hover {
  background-color: #006399;
}

.readmore-box {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  height: 40px;
}

.verse-card {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 2rem;
}

.bookmark-popup {
  z-index: 10;
  position: absolute;
  right: 0;
  background-color: grey;
  padding: 0.5rem;
  width: 300px;
  border-radius: 5px;
}
.bookmark-popup p {
  font-size: 0.9rem;
  margin-bottom: 0.25rem;
}

.readmore-box .bookmark {
  height: 100%;
  border-radius: 5px;
}

.bookmark-button {
  padding: 0 5px;
  min-width: 3.5rem;
}
@media screen and (max-width: 400px) {
  .icon-btn {
    padding: 8px;
  }
}
