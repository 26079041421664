.spiritual-gifts-assessment-link-page {
    text-align: center;
    padding: 20px;
    color: grey;
    height: 60vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.spiritual-gifts-assessment-link-page h1 {
    margin-bottom: 30px;
    color: rgb(0, 172, 0);
    /* font-family: 'Rokkitt', serif; */
}

.spiritual-gifts-assessment-spinner {
    display: flex;
    margin: 20px;
    gap: 0.8rem;
    justify-content: center;
    font-size: 0.6rem;
    color: rgb(227, 227, 227);
}

.spinner1 {
    animation: scale 0.8s alternate infinite linear,
    colorChange 0.8s alternate infinite linear;
}

.spinner2 {
    animation: scale 0.8s 0.8s alternate infinite linear,
    colorChange 0.8s 0.8s alternate infinite linear;;
}

@keyframes scale {
    to {
        scale: 1.5
    }
}

@keyframes colorChange {
    to {
        color: rgb(170, 170, 170);
    }
}