.search-page-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 4rem;
    font-weight: bold;
}

.search-page-content Link {
    font-weight: bold;
}

.search-page .title {
    font-size: 48px;
}

.search-page-body > div {
    display: flex;
    gap: 1rem;
    align-items: center;
}


.popup-overlay {
    background: var(--background-color);
}


