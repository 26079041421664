.signin-page-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 4rem;
    gap: 1rem;
}

/* .signin-page a {
  color: white;
}

.signin-page a:hover {
  color: #0056b3 ;
} */

.signin-form .form-body {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 1rem 0;
}

.signin-form .form-input {
    display: grid;
    grid-template-columns: 100px auto;
}

.password-input {
    position: relative;
}

.show-password-button {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 40px;
    margin: auto;
    padding: auto;
}

.signin-form input {
    width: 300px;
    height: 30px;
    border-radius: 5px;
}

@media screen and (max-width: 800px) {
    .signin-form .form-input {
        display: flex;
        flex-direction: column;
    }
}