/* bible related */
.bible-content {
    display: grid;
    grid-template-columns: 35% auto;
    gap: 2rem;
    align-items: center;
}

.bible-content .left-section {
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.bible-content .right-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.bible-content .left-item {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: center;
}

.bible-link-menu {
    height: 60vh;
    background-color: var(--background-color);
    overflow: auto;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    position: relative;
}

.bible-link-menu::-webkit-scrollbar-track {
    background-color: #f1f1f1;
}

.bible-link-menu::-webkit-scrollbar-thumb {
    background-color: gray; /* color of the thumb itself */
}


.search-result-page .bible-link-menu {
    height: 50vh;
}

.scripture-link {
    font-size: 20px;
    font-weight: 600;
}


.sc-title {
    font-size: 2rem;
    font-weight: 300;
}

.sc-content {
    font-weight: bold;
    height: fit-content;
}

.topic-parent {
    font-size: 2rem;
}

.verse-font {
    font-size: 1.2rem;
}

.highlight {
    background-color: rgb(89, 89, 8);
}

.tab-group {
    display: flex;
    border-bottom: solid 1px grey;
    color: white;
}

.tab-group>div {
    padding: 0.2rem 1rem;
    cursor: pointer;
}
.tab-group>div.selected {
    background-color: grey;
    color: white;
}

.category-div {
    position: relative;
}
.subject-div {
    z-index: 5;
    position: absolute;
    padding: 0.5rem;
    background-color: rgb(69, 69, 69);
    border-radius: 5px;
    margin-top: 0.5rem;
}

.subject-div .btn-close {
    color: white;
    cursor: pointer;
}

.subject-div .btn-close:hover{
    color: var(--hover-color3)
}

.bible-menu-backdrop {
    z-index: 4;
    background-color: #0C111580;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
}

.scripture-link.clicked:before, .scripture-link.clicked:focus:after {
  width: calc(100% - 5%); /* Adjust the length of the line */
}

#category-search {
    max-width: 300px;
    background-color: white ;
    color: black;
    padding: 0 0.5rem;
}


#category-search::placeholder {
    color: black;
}


@media screen and (max-width: 1200px) {
    .bible-content {
        gap: 1rem;
    }
}

/* Mobile and Tablet Devices*/
@media screen and (max-width: 800px) {
    .bible-content {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }

    .bible-content .left-section {
        gap: 0;
    }

    .bible-content .left-item {
        gap: 0;
    }

    .sc-title {
        font-size: 1.2rem;
        font-weight: 300;
    }

    .sc-content {
        font-size: 1rem;
    }

    .bible-link-menu {
        height: 60vh;
    }


}

@media screen and (max-width: 400px) {
    .bible-content {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }

    .sc-title {
        font-size: 1.5rem;
        font-weight: 300;
    }

    .sc-content {
        font-size: 1rem;
    }

    .bible-link-menu {
        padding: 0.5rem;
        height: 50vh;
    }

    .scripture-link {
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.2rem;
    }

    .topic-parent {
        font-size: 1.5rem;
    }

    .verse-font {
        font-size: 1rem;
    }
}


.scripture-link {
  position: relative;
  text-decoration: none;
  color: white;
  cursor: pointer;
  width: fit-content;
  padding: 0.5rem 0;
}

.scripture-link:before, .scripture-link:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 2px;
  background-color:#3498db; /* Change the color here */
  transition: width 0.3s ease-in-out, left 0.3s ease-in-out;
}

.scripture-link:before {
  transform: translateX(-50%);
}

.scripture-link:after {
  transform: translateX(-50%);
}

.scripture-link:hover:before, .scripture-link:hover:after {
  width: calc(100% - 20px); /* Adjust the length of the line */
}
