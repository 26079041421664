.dropdown-help-item {
    display: block;
    width: 100%;
    padding: 0.25rem 1.5rem;
    clear: both;
    font-weight: 400;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    display: flex;
    align-items: center;
}

.dropdown-help-item a:hover {
    cursor: pointer;
    color: #0056b3;
    text-decoration: none;
}

.icon-help {
    color: white;
    padding: 3px;
    margin-right: 10px;
    background-color: #9aa6b2;;
}
