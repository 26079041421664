.left-panel {
    height: 100vh;
    background-color: var(--header-color);
    color: white;
    width: 360px;
    padding: 2rem 1rem;
    position: sticky;
    top: 0;
    bottom: 0;
    flex-shrink: 0;
    z-index: 15;
}

.left-panel-content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: 100%;
}

.homeLink {
    position: relative;
    transition: color 0.3s ease, transform 0.3s ease;
    height: fit-content;
    width: fit-content;
    text-decoration: none;
    color: white;
    display: inline-block;
    font-size: 24px;
}

.homeLink::before,
.homeLink::after {
    content: '';
    position: absolute;
    bottom: -0.5rem;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: #00a2ff; /* Underline color */
    transform-origin: center;
    transform: scaleX(0); /* Initially scale to 0, hidden */
    transition: transform 0.3s ease;
}

.homeLink:hover::before,
.homeLink:hover::after {
    transform: scaleX(1); /* Scale to 1 on hover, visible */
}

.homeLink:hover {
    color: white;
    cursor: pointer;
}


#toggle-nav-button {
    position: absolute;
    z-index: 30;
    display: none;
    padding: 0.5rem;
}

.search-history-box {
    max-height: 100%;
    overflow: auto;
    padding-right: 1rem;
}

.search-history-box .content {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 0.5rem 0;
}

.search-history-topic {
    position: relative;
    left: 10px;
}

.search-history-text {
    position: relative;
}

.search-history-box .checkbox {
    position: absolute;
    display: block;
}

.search-history-box .checkbox-hidden {
    display: none;
}

.search-history-box .topic-text, .search-history-box .search-text {
    margin-left: 1.3rem;
}

/* Mobile and Tablet Devices*/
@media screen and (max-width: 800px) {
    .left-panel {
        position: absolute;
        z-index: 5;
        width: 100vw;
        transition: all 0.3s;
        transform: translateX(-100%);
        padding-top: 4rem;
    }

    .move-right {
        transform: translateX(0); /* Change from -100% to 0 for moving right */
        transition: all 0.3s;
    }

    #toggle-nav-button {
        display: block;
    }
}

@media screen and (max-width: 1200px) {
    .left-panel {
        width: 300px;
    }

}
  
