
.custom-link {
    position: relative;
    transition: color 0.3s ease, transform 0.3s ease;
    height: fit-content;
    width: fit-content;
    text-decoration: none;
    color: white;
    display: inline-block;
}

.custom-link::before,
.custom-link::after {
    content: '';
    position: absolute;
    bottom: -0.5rem;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: #00a2ff; /* Underline color */
    transform-origin: center;
    transform: scaleX(0); /* Initially scale to 0, hidden */
    transition: transform 0.3s ease;
}

.custom-link:hover::before,
.custom-link:hover::after {
    transform: scaleX(1); /* Scale to 1 on hover, visible */
}

.custom-link:hover {
    color: white;
    cursor: pointer;
}
