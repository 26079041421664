.about-intro-content {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.about-header {
    text-align: center;
    font-size: 32px;
    line-height: 1.125;
    font-weight: 600;
    letter-spacing: .004em;
}

.about-intro-text {
    background-color: var(--background-color);
}

.subheader-2 {
    text-align: center;
    padding: 10px;
}

.MuiContainer-root {
    line-height: 2rem;
}

.scripturecast-mission {
    padding-bottom: 10px;
}

.site-description {
    margin-bottom: 1.5rem;
    position: relative;
    letter-spacing: 1px;
}

.site-description:before {
    position: absolute;
    top: 67px;
    width: 220px;
    height: 1px;
    content: " ";
    background-color: white;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.site-description:after {
    position: absolute;
    top: 55px;
    height: 24px;
    width: 24px;
    content: " ";
    background-color: transparent;
    border: 1px solid white;
    left: 0;
    right: 0;
    margin: 0 auto;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.widget-title {
    padding: 10px;
    margin: 0 0 25px;
    text-align: center;
    word-wrap: break-word;
    position: relative;
    border-bottom: white 2px solid;

}

@media screen and (max-width: 400px) {
}