.footer-bottom {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 5;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-bottom: 1rem;
    margin-right: 1rem;
}


@media screen and (max-width: 480px) {
    .footer-bottom {
        padding-bottom: 1.5rem;
        margin-right: 0.5rem;
    }


}