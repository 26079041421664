.contact-us-row {
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    display: flex;
    background-color: var(--background-color);
}

.contact-us-col {
    min-height: 1px;
}

.elementor-widget-wrap {
    position: relative;
    width: 100%;
    flex-wrap: wrap;
    align-content: flex-start;
    padding-top: 30px;
}

.content-element {
    width: 100%;
}

.content-element {
    line-height: 2.1em;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

.header-element {
    font-size: 45px;
    font-weight: 300;
    width: 100%;
    margin-bottom: 20px;
}

.footer-element {
    width: 100%;
}

.footer-element p {
    line-height: 2.1em;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

.submit-button button {
    padding: 6px 33px;
    font-size: 16px;
    text-align: center;
    border: none;
    color: #fff;
    font-weight: 500;
    border-radius: 5px;
    font-size: 17px;
    background-color: #9aa6b2;
}

.fields-container {
    margin-top: 16px;
    padding-top: 0;
}

.input-container {
    margin-top: 3px;
}

.name-first {
    padding-right: 16px;
}

.name-last {

}

.form-fields input {
    width: calc(50% - 8px);
    display: inherit !important;
}


.text-area {
    resize: none;
    height: 160px !important;
    width: 100%;
}

.form-footer {
    padding: 15px 0px;
}

@media screen and (max-width: 900px) {
    .contact-us-row {
        width: 100%;
    }

    .form-fields input {
        width: 100% !important;
    }

    .input-first-name {
        margin-bottom: 10px;
    }
}


@media screen and (max-width: 641px) {
    .contact-us-row {
        width: 100%;
    }

    .form-fields input {
        width: 100% !important;
    }
}


