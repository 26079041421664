body {
    margin: 0;
    font-family: 'Helvetica Neue', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
    --app-background: #0C1115;
    --background-color: #0C111580;
    --hover-color: #0056b3;
    --header-color: #492625;
    --hover-color2: rgb(255, 255, 157);
    --hover-color3: rgb(255, 255, 0);
}