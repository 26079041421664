.copyright {
    color: grey;
    font-size: 0.8em;
    display: block;
    position: fixed;
    bottom: 0vh;
    right: 0;
    text-align: right;
    margin-left: 300px;
}

@media screen and (max-width: 800px) {
    .copyright {
        font-size: 0.6em;
        margin-left: 0;

    }
}


