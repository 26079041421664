.bookmark-out {
  position: absolute;
  background-color: grey;
  z-index: 5;
  top: 3rem;
  padding: 1rem;
  border-radius: 5px;
  width: 100%;
  max-width: min(80vw, 400px);
  color: white;
  font-size: 0.8rem;
  scrollbar-color: #b6b6b6 #eee;
  scroll-behavior: smooth;
  margin-right: 1rem;
  right: 0;
}

#bm-tooltip {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 0 5px;
  font-size: 0.8rem;
  font-style: italic;
  position: absolute;
  color: black;
  border-radius: 2px;
  z-index: 30;
  right: 30px;
}

#star {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0.5rem;
  position: relative;
}

.bm-item-div {
  display: flex;
  gap: 0.3rem;
  overflow: hidden;
  width: 100%;
}

.bm-list-title {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0.5rem 0;
  align-items: center;
}

.current-bm-buttons {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
}

#bm-name {
  width: 100%;
}

#del-tooltip {
  width: auto;
  font-style: italic;
}

#del-tooltip p {
  margin: 0;
}

.bookmark-select-checkbox {
  height: 12px;
  width: 12px;
  cursor: pointer;
  width: auto;
}

#bm-name {
  border: solid rgb(205, 205, 205) 1px;
  border-radius: 2px;
  width: 100%;
}

.bookmark-select-checkbox-div {
  display: flex;
  gap: 5px;
  align-items: center;
}

.bookmark-select-checkbox-div p {
  margin: 0;
}

.dropdown-divider {
  margin: 0.1rem 0;
}

#bookmark-item {
  display: block;
  height: 40px;
  width: 260px;
}

.bm-list ul {
  padding: 0;
}

.bm-item-div .dropstart {
  display: none;
}

.bm-item-div:hover .dropstart {
  display: block;
}

.bm-item-div .dropstart.show {
  display: block;
}

.bookmark-list-item {
  cursor: pointer;
  align-items: center;
  width: fit-content;
  white-space: nowrap;
  width: calc(100% - 40px);
  overflow: hidden;
  text-decoration: none;
  color: white;
  padding: 0.2rem 0.5rem;
}

.rename-bookmark-input {
  width: calc(100% - 40px);
}

.bookmark-list-item p {
  margin-bottom: 0;
}

#bm-link {
  text-decoration: none;
  color: white;
  padding: 0.3rem;
  width: 100%;
}

.bookmark-list-item:hover {
  background-color: rgba(0, 0, 0, 0.2);
  color: white;
}

.bookmark-list-item a {
  text-decoration: none;
  color: rgb(95, 95, 95);
}

#bookmark-item:focus {
  background-color: #e9ecef;
}

#bookmark-text {
  margin-top: 8px;
  margin-bottom: 0;
}

.bookmark-main-header {
  position: sticky;
  top: 0;
  z-index: 2;
}

.folder-name:hover {
  color: black;
  transition: all 0.3s;
}

.bookmark-body-header {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  padding-bottom: 0.5rem;
}
.bookmark-box .title {
  text-align: center;
  font-size: 1.2rem;
}
.bm-list {
  max-height: calc(100vh - 400px);
  overflow: auto;
  position: relative;
  padding-bottom: 2rem;
}

.bookmark-box p {
  margin-bottom: 0;
}

.folder-name .icon {
  font-size: 14px;
  margin-right: 0.25rem;
}

.handle-list-div {
  display: flex;
  width: 100%;
  padding-top: 5px;
  gap: 0.5rem;
  justify-content: left;
}

.bookmark-checkbox {
  position: absolute;
  display: block;
  cursor: pointer;
  z-index: 5;
  padding: 0.5rem 1rem 0.5rem 0;
}

.input-bm-div {
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0;
  border-bottom: solid rgba(255, 255, 255, 0.2) 1px;
}

.input-bm-item {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: min(100%, 600px);
  min-width: 200px;
}

.no-bm {
  text-align: center;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: white;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 1rem;
}

::-webkit-scrollbar-thumb {
  background: #cecece;
  border-radius: 1rem;
  cursor: pointer;
}

::-webkit-scrollbar-thumb:hover {
  background: #8b8b8b;
}

#bookmark-out input[type='checkbox'] {
  height: 20px;
}

.bm-list .btn-transparent {
  background-color: transparent;
  border: none;
  padding: 0 0.3rem;
}

.bm-list .btn:focus {
  box-shadow: none !important;
}

.bm-list .btn-transparent.show {
  background-color: black;
}

.bm-list .dropdown-toggle::after {
  display: none !important;
}

.rename-input {
  width: 100%;
}

.desktop-only {
  display: inline-block;
}

@media screen and (max-width: 800px) {
  .bookmark-out {
    font-size: 0.8rem;
    padding: 0.5rem;
  }

  .bookmark-select-checkbox {
    width: 0.8rem;
  }

  .bookmark-body-header {
    flex-direction: column;
  }
  .desktop-only {
    display: none;
  }
}
