.forgot-password-page {
    margin-top: 4rem;
    width: 100%;
}

.forgot-password-page .box-style1 {
    margin: auto;
}

.forgot-password {
    justify-content: center;
}

@media screen and (max-width: 641px) {
    .login-container {
        width: 80%;
    }
}