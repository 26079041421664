
* {
    box-sizing: border-box;
}

.podcast-page {
    display: flex;
    overflow: auto;
    height: 100%;
    flex-direction: column;
    gap: 1rem;
}

.pc-text-box {
    width: 100%;
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.selected {
    color: #d09d9b;
}


.subscribe-button {
    color: white;
    font-size: 0.8rem;
    border-radius: 20px;
    width: 100px;
    height: 1.8rem;
    margin-top: 5px;
}

.subscribe {
    background-color: #492625;
}

.unsubscribe {
    background-color: grey;
}

.bell {
    margin-top: -5px;
    font-size: 0.8rem;
}

.latest p {
    margin: 0;
    font-size: 1.5rem;
}

.selected .play-icon {
    color: #d09d9b;
}

.podcast-header {
    text-align: center;
}

.audio-player {
    display: flex;
    padding: 1rem;
    background-color: #f0f0f2;
    align-items: center;
    height: 100%;
    width: 100%;
    max-width: 800px;
    margin: auto;
}

.timer {
    display: flex;
    width: 100%;
    gap: 1rem;
}

/* audio info */
.audio-info {
    width: 100%;
    display: flex;
    gap: 0.5rem;
    text-align: center;
    overflow-x: hidden;
    flex-direction: column;
}

.text1 {
    color: grey;
    overflow-x: hidden;

}

.newest-tracks {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin: auto;
}

.all-episodes {
    display: flex;
    height: 100%;
    flex-direction: column;
    gap: 1rem;
}

.newest {
    display: flex;
    padding: 1rem;
    width: 100%;
    max-width: 400px;
    height: 100%;
    cursor: pointer;
    color: black;
    background-color: #d09d9b;
    justify-content: space-between;
}

.square-play {
    height: 32px;
    width: 32px;
    margin-top: 26px;
    border: solid black 1px;
    border-radius: 10px;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    color: black;
    padding: 5px 3px 4px 5px;
}

.newest-title {
    font-weight: bold;
    color: black;
    font-size: medium;
}

.newest:hover .newest-title:hover {
    color: #492625;
}

.newest:hover .square-play:hover {
    border: solid var(--header-color) 1px;
    color: #492625;
}


.newest-secondline {
    display: flex;
    justify-content: space-between;
    color: grey;
    font-size: small;
}

.title1 {
    color: #492625;
    font-weight: bold;
    width: fit-content;
    min-width: 100%;
    font-size: 1.2rem;

}


.newest-box > p {
    font-size: 1.5rem;
}

/* audio image */
.icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.controls-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.audio-image-div {
    display: flex;
}

.audio-image {
    width: 120px;
    height: 120px;
}

.audio-icon {
    font-size: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    width: 80px;
    border-radius: 50%;
    background: #989898;
}

.episode {
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    border-bottom: solid rgb(180, 180, 180) 1px;
}

.ep-grid {
    display: grid;
    font-size: small;
    font-style: italic;
    grid-template-columns: 50% 30% auto;

}

.audio-image img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    vertical-align: middle;
}

ul {
    list-style: none;
    width: 100%;
}

.podcast-list {
    display: flex;
    text-align: left;
    flex-direction: column;
    padding: 1rem;
    font-size: 1.2rem;
    background-color: var(--background-color);
}


.play-icon {
    padding-bottom: 0.2rem;
    font-size: 1.5rem;
}

.podcast:hover, .podcast:hover .play-icon {
    color: var(--hover-color2);
}

/* controls */

.controls {
    display: flex;
    justify-content: center;
    align-self: center;
    justify-self: center;
}

.controls button, .volume button {
    border: none;
    background-color: transparent;
    margin-right: 8px;
    cursor: pointer;
}

button svg {
    font-size: 20px;
}

.play-button {
    background-color: #b3b3b3;
    border-radius: 50% 50%;

}

.volume {
    display: flex;
    align-items: center;
}

.volume svg {
    fill: black;
}

.volume input[type="range"] {
    width: 50%;
    height: 6px;
}

.volume button {
    margin: 0;
}

.progress {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
}

/* time */
.time {
    display: flex;
    height: inherit;
    align-items: center;
    color: #333;
    font-size: 13px;
}

.time.current {
    color: #f50;
}

/* Top bar */
.top__bar {
    font-size: 0.875rem;
    line-height: 1.25rem;
    text-align: center;
    padding-top: .5rem;
    padding-bottom: .5rem;
    background-color: #fafafa;
    border-bottom: 1px solid #f0f0f2;
}

.top__bar a {
    color: inherit;
}

.top__bar p {
    margin: 0;
}


/* =========================
Custom Input range 
=============================*/

/* Input range - chrome and safari */

input[type="range"] {
    --range-progress: 0;

    -webkit-appearance: none;
    position: relative;
    background: #ccc;
    width: 100%;
    height: 12px;
    border-radius: 12px;
    cursor: pointer;
}

/* Input range - firefox */
input[type="range"]::-moz-range-track {
    position: relative;
    background: #ccc;
    width: 100%;
    height: 12px;
    border-radius: 12px;
    cursor: pointer;
    border: none;
    outline: none;
}

/* played progress length - Chrome & safari*/
input[type="range"]::before {
    content: '';
    height: 12px;
    background: #f50;
    width: var(--range-progress);
    border-radius: 12px;
    position: absolute;
    top: 0;
    left: 0;

}

/* played progress length - firefox */
input[type="range"]::-moz-range-progress {
    background: #492625;
    border-radius: 12px;
    height: 12px;
}


/* slider thumb - chrome and safari */
input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    border: none;
    background-color: transparent;
    cursor: pointer;
    position: relative;
}

/* slider thumb - firefox */
input[type="range"]::-moz-range-thumb {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background: none;
    cursor: pointer;
    border: none;
    position: relative;
}

.podcast-subheader {
    font-size: 16px;
    font-style: italic;
}

.podcasts-row {
    margin: auto;
    display: flex;
}

.podcasts-col {
    min-height: 1px;
}

.elementor-widget-wrap {
    position: relative;
    width: 100%;
    flex-wrap: wrap;
    align-content: flex-start;
    padding-top: 30px;
}

.content-element {
    width: 100%;
}

.content-element {
    line-height: 2.1em;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

.header-element {
    font-size: 45px;
    font-weight: 300;
    width: 100%;
    margin-bottom: 20px;
}

.footer-element {
    width: 100%;
}

.footer-element p {
    line-height: 2.1em;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

.submit-button button {
    padding: 6px 33px;
    font-size: 16px;
    text-align: center;
    border: none;
    color: #fff;
    font-weight: 500;
    border-radius: 5px;
    font-size: 17px;
    background-color: #9aa6b2;
}

.fields-container {
    margin-top: 16px;
    padding-top: 0;
}

.input-container {
    margin-top: 3px;
}

.podcast-page .name {
    padding-right: 16px;
}

.form-fields input {
    width: calc(50% - 8px);
    display: inherit !important;
}

.text-area1 {
    width: 60%;
    height: 160px !important;
}

.form-footer {
    padding: 15px 0px;
}

.podcast-player {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    padding-top: 2em;
}

.timer-control {
    display: flex;
}

@media screen and (max-width: 1200px) {

    .podcast-page {
        display: flex;
        overflow: auto;
        flex-direction: column;
        height: 100%;
        gap: 1rem;
    }

    .podcast-page h1 {
        font-size: 1.5rem;
    }

    .podcast-page h2 {
        font-size: 1.2rem;
    }

    .text1 p {
        font-size: 0.8rem;
    }

    .newest {
        height: fit-content;
    }

    .pc-text-box {
        height: auto;
        overflow: auto;
        width: 100%;
    }

    .newest-secondline {
        display: flex;
        flex-direction: column;
        color: grey;
        font-size: small;
        padding-bottom: 10px;
    }

    .newest-secondline p {
        margin: 0;
    }

    ul {
        list-style: none;
        width: 100%;
        padding: 0;
    }

    .newest-tracks {
        display: flex;
        flex-direction: column;
    }

    .text1 > p {
        margin: 0;
        font-size: 1rem;
    }

    .podcasts-row {
        width: 100%;
    }

    .form-fields input {
        width: 100% !important;
    }

    .podcast-player {
        padding-top: 5em;
    }

    .podcasts-row {
        padding-top: 8em;
    }


    .title-play {
        animation: run 15s linear infinite;
    }

    .podcast-list {
        padding: 0;
    }

    .audio-info {
        gap: 0;
    }

    .controls {
        padding: 0;
    }
}

@media screen and (max-width: 800px) {
    .title1 {
        font-size: 1rem;
    }

}

@media screen and (max-width: 550px) {
    .timer-control {
        flex-direction: column;
    }

    .timer {
        justify-content: space-between;
    }

    .volume {
        justify-content: flex-end;
    }

    .newest {
        padding: 0.5rem;
    }
}


@keyframes run {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(-100%);
    }
}



