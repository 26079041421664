.verse-page {
    padding-bottom: 2rem;
    position: relative;
}


.verse-page-content {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
}

 .title-bar {
    border-top: 5px var(--header-color) solid;
    display: flex;
    justify-content: space-between;
}

.title-bar .right-section {
    display: flex;
    gap: 1rem;
}

.verse-content {
    padding: 1rem;
    width: 100%;
    margin: auto;
    max-width: 800px;
    display: flex;
    flex-direction: column;
    background-color: var(--background-color);
}

.verse-content > div {
    padding: 0.5rem;
}

.chapter-content {
    border-top: white 1px solid;
    border-bottom: white 1px solid;
    padding: 0.5rem 0;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.listen-button, .copy-button {
    position: relative;
    cursor: pointer;
}

.listen-button:hover span:hover, .copy-button:hover span:hover {
    color: var(--hover-color2);
}

.copy-tooltip {
    position: absolute;
    padding: 0.2rem 0.5rem;
    background-color: grey;
    border-radius: 2px;
    display: block;
    right: 0;
    white-space: nowrap;
}

.tooltip-hidden {
    display: none;
}

.overview-audio {
    position: relative;
}

.cancel-button {
    position: absolute;
    z-index: 5;
    right: 0;
    top: -0.2rem;
    cursor: pointer;
    height: 100%;
    color: grey;
}

.cancel-button:hover {
    color: red;
}

.link-style1.text-right {
   align-self: flex-end;
}

.ref-icon {
    width: 16px;
}

.overview-header {
    width: 100%;
    position: sticky;
     max-width: 800px;
     margin: auto;
     top: 0;
     z-index: 10;
    background-color: var(--app-background);
}

.overview-header>div {
    padding : 0.5rem;
    width: 100%;
    
}

.topic-audio {
    width: 100%;  
}
.topic-audio>div {
    margin: auto;
    width: fit-content;
  
}

.verse-audio {
    width: 100%;
    position: fixed;
    display: block;
    bottom: 6.5rem;
    right: 1rem;
    z-index: 10;
}

.verse-audio>div {
    width: fit-content;
    float: right;
}

audio {
    height: 30px !important;
}
@media screen and (max-width: 800px) {
  .topic-audio {
        display: block;
        position: fixed;
        bottom: 6.5rem;
        right: 1rem;
    }
    .topic-audio>div {
        float: right;
    }

}

@media screen and (max-width: 500px) {
  .topic-audio {
        bottom: 6rem;
        right: 0;
   
    }
    .topic-audio>div {
        float:unset;
    }

    .verse-audio {
        bottom: 6rem;
        right: 0;
    }

    .verse-audio>div {
        float: unset;
        margin: auto;
    }
    .topic.audio-div {
        width: 100%;
    }
}

.highlight {
    background-color: #0237a2;
    transition: background-color 1s ease;
}
