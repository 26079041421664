.register-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.register-page label {
    margin: 0;
}

.register-page select {
    width: fit-content;
}

.register-form-body {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;
}

.register-form-body > div {
    width: 100%;
}

.register-page input[type='text'], .register-page input[type='password'] {
    width: 100%;
    max-width: 400px;
}

.register-page .password-input {
    position: relative;
}

.register-page .email-section {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
}

.register-page .email-section > div {
    flex: 1;
}

.register-page .name-section {
    display: flex;
    gap: 1rem;
}

.register-page .name-section > .name {
    display: flex;
    gap: 1rem;
    width: 100%;
}

.register-page .name-section > .name > div {
    flex: 1;
}

.agree-signup-container {
    display: flex;
    justify-content: center;
    font-style: italic;
}

.show-password-icon2 {
    position: absolute;
    right: 2.5rem;
    top: 50%;
    transform: translateY(-50%);
}

.register-page .password-section {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
}

.register-page .password-section > div {
    flex: 1;
}

.register-page .button-section {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;
}

.register-page .button-section > div {
    width: fit-content;
}

@media screen and (max-width: 641px) {

}