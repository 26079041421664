#main-donate-button {
    color: white;
    cursor: pointer;
    z-index: 5;
    font-size: 1rem;
    padding: 0.3rem 0.6rem;
    border-radius: 10px;
    background-color: var(--background-color);
    width: fit-content;
    border: grey 1px solid;

}

#main-donate-button:hover {
    color: var(--hover-color2);
}


@media screen and (max-width: 400px) {
    #main-donate-button {
        font-size: 0.8rem;
        padding: 0.3rem 0.6rem;
        border-radius: 10px;
    }

}